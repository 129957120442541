import React from 'react'
import { Layout } from '../components/layout/Layout'
import { HeadingMd, HeadingSm } from '../components/heading/Heading'
import { TextBanner } from '../components/text-banner/TextBanner'
import { Cards } from '../components/cards/Cards'
import { Quote } from '../components/quote/Quote'

const TrajectPage = () => {
  return (
    <Layout title="Traject" desc="Het traject bestaat uit contactmomenten met de postpartum coach en e-health coaching.">
      <TextBanner>
        <HeadingMd h={2}>Het traject</HeadingMd>
        <p>De postpartum coach is er voor jou! Tijdens de contactmomenten met de postpartum coach kun jij je verhaal kwijt en delen over je verwachtingen, ervaringen en behoeften. De postpartum coach bekrachtigt (aanstaande) moeders om gevoelens van twijfel en onzekerheid om te buigen naar positieve gedachten en dan vervolgens te oefenen met concreet gedrag. Door gevoelens en gedachten met de postpartum coach te bespreken, te oefenen in de situatie en te evalueren groeit je zelfvertrouwen in het moederschap.</p>
        <Quote text="“Een interventie gericht op ‘postpartum zorg’, jezelf opnieuw uitvinden en elke dag mogen leren en evolueren.”" />
        <HeadingSm h={3}>Een aantal onderwerpen tijdens de interventie zijn:</HeadingSm>
        <ul>
          <li>Beleving van bevalling</li>
          <li>Lichamelijk herstel (rust, beweging/oefening, voeding en leefstijl)</li>
          <li>Zelfzorg, bespreken van gevoelens, gedachten en gedrag</li>
          <li>Balans in verschillende rollen moeder, vrouw, dochter, zus, vriendin en werknemer</li>
          <li>Rol van vader; betrokkenheid, communicatie/samenwerking, verwachtingen etc.</li>
          <li>Inzetten netwerk (evt. ter ondersteuning)</li>
          <li>Hechting met je kind</li>
          <li>Signalen en symptomen herkennen en naar handelen</li>
          <li>Terugkeer naar werk</li>
        </ul>

        <p>Een moeder hoeft niet alles te weten en niet alles te kunnen, het is belangrijk dat je zicht hebt op wat je aankan, zoals signalen bij jezelf herkennen, tijdig hulp te vragen, je netwerk te betrekken, daadkrachtig te handelen, grenzen te stellen etc.</p>

      </TextBanner>

      <Cards />
    </Layout>
  )
}

export default TrajectPage
